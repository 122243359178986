<template>
  <div style="display:unset; float:right">
    <mark
      style="position:fixed;top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0);z-index:999;"
      v-show="showSeason"
      @click.stop="showSeason=false"
    ></mark>
    <el-input
      placeholder="请选择季度"
      v-model="showValue"
      style="width:138px;"
      @focus="showElement"
    >
    </el-input>
    <el-card
      class="box-card"
      style="width:280px;padding: 0 3px 20px;margin-top:10px;position:absolute;z-index:9999"
      v-show="showSeason"
    >
      <div
        slot="header"
        class="clearfix"
        style="text-align:center;padding:0"
      >
        <button
          type="button"
          aria-label="前一年"
          class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"
          @click="prev"
        ></button>
        <span
          role="button"
          class="el-date-picker__header-label"
        >{{year}}年</span>
        <button
          type="button"
          aria-label="后一年"
          @click="next"
          class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"
        ></button>
      </div>
      <div
        class="text item"
        style="text-align:center;"
      >
        <el-button
          type="text"
          size="medium"
          style="width:40%;color:#808191;float:left;"
          :class="defaultSeason[0] ? 'selected' : (noSelect1?'isDisabled':'')"
          @click="selectSeason(0)"
          :disabled="noSelect1"
        >第一季度</el-button>
        <el-button
          type="text"
          size="medium"
          style="float:right;width:40%;color: #808191;"
          :class="defaultSeason[1] ? 'selected' : (noSelect2?'isDisabled':'')"
          @click="selectSeason(1)"
          :disabled="noSelect2"
        >第二季度</el-button>
      </div>
      <div
        class="text item"
        style="text-align:center;"
      >
        <el-button
          type="text"
          size="medium"
          style="width:40%;color:#808191;float:left;"
          :class="defaultSeason[2] ? 'selected' : (noSelect2?'isDisabled':'')"
          :disabled="noSelect3"
          @click="selectSeason(2)"
        >第三季度</el-button>
        <el-button
          type="text"
          size="medium"
          style="float:right;width:40%;color: #808191;"
          :class="defaultSeason[3] ? 'selected' : (noSelect3?'isDisabled':'')"
          :disabled="noSelect4"
          @click="selectSeason(3)"
        >第四季度</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
/**
 * @file:  View 组件 季节选择控件
 * @author: v_zhuchun
 * @date: 2019-05-23
 * @description: UI组件  可选择季节
 * @api: valueArr : 季度value defalut['01-03', '04-06', '07-09', '10-12'] 默认值待设置
 */
export default {
  props: {
    valueArr: {
      default: () => {
        return ['01-03', '04-06', '07-09', '10-12']
      },
      type: Array
    },
    getValue: {
      default: () => { },
      type: Function
    },
    defaultValue: {
      default: '',
      type: String
    },
    banDate: {
      type: Number
    },
    quarterTime: {
      type: String
    }
  },
  data () {
    return {
      showSeason: false,
      season: '',
      year: new Date().getFullYear(), // 可选择的时间年份
      showValue: '',
      noSelect1: false,
      noSelect2: false,
      noSelect3: false,
      noSelect4: false,
      defaultSeason: [false, false, false, false] // 默认选中的季度
    }
  },
  created () {
    if (this.defaultValue) {
      const value = this.defaultValue
      const arr = value.split('-')
      this.year = arr[0].slice(0, 4)
      const str = arr[0].slice(4, 6) + '-' + arr[1].slice(4, 6)
      const arrAll = this.valueArr
      this.showValue = `${this.year}年${arrAll.indexOf(str) + 1}季度`
    }
  },
  watch: {
    defaultValue: function (value, oldValue) {
      const arr = value.split('-')
      this.year = arr[0].slice(0, 4)
      const str = arr[0].slice(4, 6) + '-' + arr[1].slice(4, 6)
      const arrAll = this.valueArr
      this.showValue = `${this.year}年${arrAll.indexOf(str) + 1}季度`
    }
  },
  mounted () {
    this.setNoeSeason()
    this.getNowSeason()
    this.setDefault()
  },
  methods: {
    one () {
      this.showSeason = false
    },
    prev () {
      this.year = this.year * 1 - 1
      this.setNoeSeason()
      this.setDefault()
    },
    next () {
      this.year = this.year * 1 + 1
      this.setNoeSeason()
      this.setDefault()
    },
    showElement () {
      this.showSeason = true
      this.setDefault()
    },
    // 选中季度的样式
    setDefault () {
      const Temp = this.quarterTime.split('Q')
      const year = Number(Temp[0])
      const season = Number(Temp[1])
      console.log('year', year)
      console.log('season', season)
      console.log('this.year', this.year)
      if (year === this.year) {
        if (season === 1) {
          this.defaultSeason[0] = true
          this.defaultSeason[1] = false
          this.defaultSeason[2] = false
          this.defaultSeason[3] = false
        } else if (season === 2) {
          this.defaultSeason[0] = false
          this.defaultSeason[1] = true
          this.defaultSeason[2] = false
          this.defaultSeason[3] = false
        } else if (season === 3) {
          this.defaultSeason[0] = false
          this.defaultSeason[1] = false
          this.defaultSeason[2] = true
          this.defaultSeason[3] = false
        } else {
          this.defaultSeason[0] = false
          this.defaultSeason[1] = false
          this.defaultSeason[2] = false
          this.defaultSeason[3] = true
        }
      } else {
        this.defaultSeason[0] = false
        this.defaultSeason[1] = false
        this.defaultSeason[2] = false
        this.defaultSeason[3] = false
      }
    },
    getNowSeason () {
      const month = new Date().getMonth() + 1
      if (month <= 3) {
        this.season = 4
        this.year = this.year - 1
        this.showValue = `${this.year}年${this.season}季度`
      } else if (month >= 4 && month <= 6) {
        this.season = 1
        this.showValue = `${this.year}年${this.season}季度`
      } else if (month >= 7 && month <= 9) {
        this.season = 2
        this.showValue = `${this.year}年${this.season}季度`
      } else if (month >= 10 && month <= 12) {
        this.season = 3
        this.showValue = `${this.year}年${this.season}季度`
      }
      console.log('season', this.season)
    },
    setNoeSeason () {
      const banDate = new Date(this.banDate) // 合同开始的时间
      const banYear = banDate.getFullYear() // 合同开始的年份
      const banMonth = banDate.getMonth() + 1 // 合同开始的月份
      const date = new Date() // 当前时间
      const nowYear = date.getFullYear() // 当前的年份
      const month = date.getMonth() + 1 // 当前的年份
      if (this.year > nowYear || this.year < banYear) { // 今年以后的年份禁选、合同年份以前的年份禁选
        this.noSelect1 = true
        this.noSelect2 = true
        this.noSelect3 = true
        this.noSelect4 = true
      } else { // 合同年份到今年的年份可以选择
        if (this.year === nowYear && this.year !== banYear) { // 与今年同年，与合同年份不同年时
          if (month <= 3) { // 若现在是今年的第1季度，都禁选
            this.noSelect1 = true
            this.noSelect2 = true
            this.noSelect3 = true
            this.noSelect4 = true
          } else if (month >= 4 && month <= 6) { // 若现在是今年的第2季度，第1季度可选
            this.noSelect1 = false
            this.noSelect2 = true
            this.noSelect3 = true
            this.noSelect4 = true
          } else if (month >= 7 && month <= 9) { // 若现在是今年的第3季度，第1、2季度可选
            this.noSelect1 = false
            this.noSelect2 = false
            this.noSelect3 = true
            this.noSelect4 = true
          } else if (month >= 10 && month <= 12) { // 若现在是今年的第4季度，第1、2、3季度可选
            this.noSelect1 = false
            this.noSelect2 = false
            this.noSelect3 = false
            this.noSelect4 = true
          }
        } else if (this.year === banYear && this.year !== nowYear) { // 与今年不同年，与合同年份同年时
          if (banMonth <= 3) {
            this.noSelect1 = false
            this.noSelect2 = false
            this.noSelect3 = false
            this.noSelect4 = false
          } else if (banMonth >= 4 && banMonth <= 6) {
            this.noSelect1 = true
            this.noSelect2 = false
            this.noSelect3 = false
            this.noSelect4 = false
          } else if (banMonth >= 7 && banMonth <= 9) {
            this.noSelect1 = true
            this.noSelect2 = true
            this.noSelect3 = false
            this.noSelect4 = false
          } else if (banMonth >= 10 && banMonth <= 12) {
            this.noSelect1 = true
            this.noSelect2 = true
            this.noSelect3 = true
            this.noSelect4 = false
          }
        } else if (this.year === banYear && this.year === nowYear) { // 与今年同年，与合同年份同年时
          if (banMonth <= 3) { // 若合同从第1季度开始
            if (month <= 3) { // 若当前是第1季度，都不能选
              this.noSelect1 = true
              this.noSelect2 = true
              this.noSelect3 = true
              this.noSelect4 = true
            } else if (month >= 4 && month <= 6) { // 若当前是第2季度，第1季度能选
              this.noSelect1 = false
              this.noSelect2 = true
              this.noSelect3 = true
              this.noSelect4 = true
            } else if (month >= 7 && month <= 9) { // 若当前是第3季度，第1，2季度能选
              this.noSelect1 = false
              this.noSelect2 = false
              this.noSelect3 = true
              this.noSelect4 = true
            } else if (month >= 10 && month <= 12) { // 若当前是第4季度，第1,,2,3季度能选
              this.noSelect1 = false
              this.noSelect2 = false
              this.noSelect3 = false
              this.noSelect4 = true
            }
          } else if (banMonth >= 4 && banMonth <= 6) { // 若合同从第2季度开始
            if (month >= 4 && month <= 6) { // 若当前是第2季度，都不能选
              this.noSelect1 = true
              this.noSelect2 = true
              this.noSelect3 = true
              this.noSelect4 = true
            } else if (month >= 7 && month <= 9) { // 若当前是第3季度，第2季度能选
              this.noSelect1 = true
              this.noSelect2 = false
              this.noSelect3 = true
              this.noSelect4 = true
            } else if (month >= 10 && month <= 12) { // 若当前是第4季度，第2,3季度能选
              this.noSelect1 = true
              this.noSelect2 = false
              this.noSelect3 = false
              this.noSelect4 = true
            }
          } else if (banMonth >= 7 && banMonth <= 9) { // 若合同从第3季度开始
            if (month >= 7 && month <= 9) { // 若当前是第3季度，都不能选
              this.noSelect1 = true
              this.noSelect2 = true
              this.noSelect3 = true
              this.noSelect4 = true
            } else if (month >= 10 && month <= 12) { // 若当前是第4季度，能选第3季度
              this.noSelect1 = true
              this.noSelect2 = true
              this.noSelect3 = false
              this.noSelect4 = true
            } else { // 若当前是1、2季度，都不能选
              this.noSelect1 = true
              this.noSelect2 = true
              this.noSelect3 = true
              this.noSelect4 = true
            }
          } else if (banMonth >= 10 && banMonth <= 12) { // 若合同从第4季度开始，都不能选
            this.noSelect1 = true
            this.noSelect2 = true
            this.noSelect3 = true
            this.noSelect4 = true
          }
        } else {
          this.noSelect1 = false
          this.noSelect2 = false
          this.noSelect3 = false
          this.noSelect4 = false
        }
      }
    },
    selectSeason (i) {
      const that = this
      that.season = i + 1
      const arr = that.valueArr[i].split('-')
      that.getValue(that.year + arr[0] + '-' + that.year + arr[1])
      that.showSeason = false
      this.showValue = `${this.year}年${this.season}季度`
      this.$emit('quarter', {
        year: this.year,
        season: this.season
      })
      // this.setDefault()
    }
  }
}
</script>

<style lang="stylus" scoped>
/deep/ .el-input input
  text-align center
/deep/ .el-card
  color #fff !important
  background #272930 !important
  border 1px solid #424752 !important
  border-radius 2px !important
  box-shadow 0px 16px 30px 0px rgba(0, 0, 0, 0.6) !important
  // 上年和下年的按钮
/deep/ .el-icon-d-arrow-left
  color #fff !important
  width 24px
  height 24px
  border-radius 24px
  margin-top 3px
/deep/ .el-icon-d-arrow-left:hover
  color #fff !important
  width 24px
  height 24px
  border-radius 24px
  margin-top 3px
  background #2c2e38
/deep/ .el-icon-d-arrow-left:active
  color #fff
  width 24px
  height 24px
  line-height 24px
  background #f89407
  border-radius 24px
  font-weight unset
/deep/ .el-icon-d-arrow-right
  color #fff !important
  width 24px
  height 24px
  border-radius 24px
  margin-top 3px
/deep/ .el-icon-d-arrow-right:hover
  color #fff !important
  width 24px
  height 24px
  border-radius 24px
  margin-top 3px
  background #2c2e38
/deep/ .el-icon-d-arrow-right:active
  color #fff
  width 24px
  height 24px
  line-height 24px
  background #f89407
  border-radius 24px
  font-weight unset
/deep/ .el-date-picker__header-label
  font-size 18px
  font-family PingFang SC
  font-weight bold
  color #f89407
  &:hover
    color #f89407
/deep/ .el-card__header
  border-bottom none
/deep/ .el-card__body
  padding 0
.selected
  border 1px solid #f89407
  color #f89407 !important
.isDisabled
  color rgba(128, 129, 145, 0.5) !important
  color rgba(255,255,255,0.5)
</style>
